<template>
  <div>
    <div class="font-weight-medium text-h6 mb-3">
      {{ $t("forms.add.title") }}
    </div>
    <div>
      <form-forms :schema.sync="schema" :form="form"></form-forms>
    </div>
  </div>
</template>

<script>
import FormForms from './form.vue';
export default {
  data: () => ({
    form: {
      id: null,
      name: null,
      serviceId: null
    },
    schema: []
  }),
  components: {
    FormForms
  }
};
</script>