<template>
  <div>
    <forms-add></forms-add>
  </div>
</template>


<script>
import FormsAdd from './../../components/forms/add';
export default {
  data: () => ({}),
  metaInfo() {
    return {
      title: this.$i18n.t('forms.add.title'),
    };
  },
  components: {
    FormsAdd
  }
}
</script>